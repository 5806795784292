.lcd-clock {
    display: flex;
    font-family: 'Digital-7', monospace; /* Make sure this font is imported in your project */
    color: rgb(53, 53, 143);
  }
  .digit {
    width: 1em;
    font-size: 18px;
    text-align: center;
    position: relative;
    overflow: hidden; /* Prevents the animation from being seen outside this box */
  }
  
  .digit-value {
    display: inline-block;
    animation: flow-up 0.5s ease-in-out;
  }
  
  .separator {
    padding: 0 0.2em;
  }
  
  @keyframes flow-up {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }